import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import BookingPage from "./pages/BookingPage/BookingPage";
import Navbar from "./components/Navbar/Navbar";
import FaqPage from "./pages/FaqPage/FaqPage";
import PrivacyPage from "./pages/Legal/PrivacyPage";
import CookiesPage from "./pages/Legal/CookiesPage";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import CookieConsent from "react-cookie-consent";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import PressureWashPage from "./pages/PressureWashPage/PressureWashPage";
import OdrzavanjePoslovnihProstora from "./pages/OdrzavanjePoslovnihProstora/OdrzavanjePoslovnihProstora";
import NajamUredaja from "./pages/NajamUredaja/NajamUredaja";
import StrojnoCiscenje from "./pages/StrojnoCiscenje/StrojnoCiscenje";

function App() {
  // const gtmParams = { id: "GTM-5R7Z85K5" };

  return (
    <>
      {/* <GTMProvider state={gtmParams}> */}
        <Router>
          <ScrollToTop />
          <Navbar />
          <CookieConsent
            buttonText="Prihvati"
            style={{
              color: "var(--primary)",
              background: "var(--white)",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
            buttonStyle={{
              color: "var(--white)",
              backgroundColor: "var(--primary)",
              fontSize: "1rem",
              textTransform: "uppercase",
              borderRadius: "0.5rem",
              padding: "0.5rem 1rem",
            }}
            expires={150}
          >
            Ova web stranica koristi kolačiće kako bi se osiguralo bolje
            korisničko iskustvo. Više informacija pronađite{" "}
            <Link to="/kolacici">ovdje</Link>.{" "}
          </CookieConsent>

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="strojno-ciscenje" element={<StrojnoCiscenje />} />
            <Route path="najam-uredaja" element={<NajamUredaja />} />
            <Route path="odrzavanje-poslovnih-prostora" element={<OdrzavanjePoslovnihProstora />} />
            <Route path="zastita-privatnosti" element={<PrivacyPage />} />
            <Route path="cesta-pitanja" element={<FaqPage />} />
            <Route path="kolacici" element={<CookiesPage />} />
            {/* <Route path="potvrda" element={<SuccessPage />} /> */}
            {/* <Route path="rezerviraj" element={<BookingPage />} /> */}
          </Routes>

          <Footer />
        </Router>
      {/* </GTMProvider> */}
    </>
  );
}

export default App;
